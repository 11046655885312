







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.vs-dialog__content {
  background-color: #f9fafb !important;
  background-color: rgba(249, 250, 251, var(--bg-opacity)) !important;
}
.pricing-table-1 .fusion-panel,
.pricing-table-1 .standout .fusion-panel,
.pricing-table-1 .panel-wrapper:last-child .fusion-panel,
.pricing-table-1 .panel-heading,
.pricing-table-1 .panel-body,
.pricing-table-1 .panel-footer {
  border-color: #e2e2e2;
}
.pricing-table-2 .fusion-panel,
.pricing-table-2 .standout .fusion-panel,
.pricing-table-2 .panel-wrapper:last-child .fusion-panel,
.pricing-table-2 .panel-heading,
.pricing-table-2 .panel-body,
.pricing-table-2 .panel-footer {
  border-color: #e2e2e2;
}
.pricing-table-2.full-boxed-pricing .panel-heading {
  background-color: rgba(255, 255, 255, 0);
}
@media only screen and (max-width: 800px) {
  .fusion-title.fusion-title-6 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.sub-panel {
  &:hover {
    border-color: #66999b;
    .DetailItem {
      .DetailTitle {
        color: #ea5455 !important;
        font-weight: 900;
      }
    }
  }
}
.sep-boxed-pricing .panel-heading {
  padding: 15px;
  text-align: center;
  background-color: #66999b;
  background-clip: padding-box;
  border-color: #66999b;
  border-bottom: 1px solid #e5e4e3;
  border-radius: 0;
}
.panel-container.active {
  border: #e95a5d;
  border-style: solid;
  border-width: 2px;
  .panel-heading {
    background: #e95a5d;
    .h3 {
      color: white;
    }
  }
}
.fusion-pricing-table .panel-body .price .integer-part {
  display: inline;
  color: #66999b;
  font-weight: 700;
  line-height: normal;
}
.sep-boxed-pricing .panel-body .price .integer-part {
  font-size: 45px;
}
.pricing-table-1.fusion-pricing-table .panel-body .price .integer-part {
  color: #e95a5d;
}
.fusion-pricing-table .panel-body {
  padding: 15px;
  text-align: center;
}
.panel-wrapper {
  flex: 1 0 21%;
  margin: 5px;
}
.sub-button {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 10px;
}
.fusion-pricing-table .list-group-item {
  position: relative;
  display: block;
  margin: 0;
  padding: 15px;
  text-align: center;
  background: 0 0;
  border-top: 1px solid #e5e4e3;
  border-radius: 0;
}
.panel-container {
  transition: box-shadow 0.3s;
}
.panel-container:hover {
  box-shadow: 20px 10px 40px 0 rgba(0, 0, 0, 0.5);
}
