<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Prop } from 'vue-property-decorator';
import contentModule from '@/store/modules/content.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import SubscriptionPanel from '@/modules/organisation/components/SubscriptionPanel.vue';
import { Loading } from '@/modules/auth/store/auth.service';
import * as _ from 'lodash';
import { Auth } from '../../../store/modules/auth.module';
import SignUpButton from '@/modules/marketing/pricing/SignUpButton.vue';
import SubscribeButton from '@/modules/marketing/pricing/SubscribeButton.vue';
import UpgradeButton from '@/modules/marketing/pricing/UpgradeButton.vue';

@Component({
  components: {
    SubscriptionPanel,
    SignUpButton,
    SubscribeButton,
    UpgradeButton,
  },
})
export default class OrganisationPaymentModal extends Vue {
  @Prop() isActive!: boolean;
  @Prop({ default: false }) isMarketing!: boolean;
  @Ref('paymentForm') readonly paymentForm: HTMLElement;
  activeIndex = 0;
  selected = true;
  loading = false;
  pricingOrder = ['Free Trial', 'basic', 'standard', 'premium', 'enterprise'];
  get content() {
    const contentArr: any[] = contentModule.subContent.filter((sub: any) => sub.fields.price !== 0);
    let priceArr: any[] = contentArr.sort((a, b) => {
      return this.pricingOrder.indexOf(a.fields.name.toLowerCase()) - this.pricingOrder.indexOf(b.fields.name.toLowerCase());
    });
    return priceArr;
  }

  get small() {
    if (this.content && this.content.length > 0) return this.content.find((val) => val?.fields?.name === 'Small').fields;
    return 0;
  }
  get medium() {
    if (this.content && this.content.length > 0) return this.content.find((val) => val?.fields?.name === 'Medium').fields;
    return 0;
  }
  get large() {
    if (this.content && this.content.length > 0) return this.content.find((val) => val?.fields?.name === 'Large').fields;
    return 0;
  }

  beforeDestroy() {
    this.$emit('update:isActive', false);
  }
  destroyed() {
    this.$emit('update:isActive', false);
  }
  get paymentData() {
    return tenantModule.paymentData;
  }
  pop() {
    this.$emit('update:isActive', false);
  }
  toggle(index: number) {
    this.activeIndex = index;
  }

  async signIn() {
    await this.$msal.signIn({ state: 'register' });
  }

  async signUp(plan: string) {
    if (!Auth.isAuthenticated) {
      await this.$msal.signIn({ state: 'register' });
    } else {
      const response = await this.$swal({
        icon: 'info',
        title: 'Confirm ' + plan + ' plan',
        text: 'To complete you registration please click OK to go to Payfast and complete your payment',
        position: 'center',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      // if (response.isConfirmed) {
      //   this.getPaymentData(plan);
      // }
    }
  }

  done = false;
}
</script>

<template>
  <div class="">
    <div ref="paymentForm" class="invisible bg-gray-50 hidden" v-html="paymentData"></div>

    <div
      class="xl:bg-contain bg-top bg-no-repeat data-view w-full"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/pages/wave-gray.webp') + ')',
      }"
    >
      <div class="">
        <div class="bg-white dark:bg-gray-700 dark:text-white">
          <div class="pt-20 pb-0 max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div class="sm:flex sm:flex-col sm:align-center">
              <h1 class="md:text-4xl text-gray-900 font-bold font-heading dark:text-white sm:text-center">Pricing Plans</h1>
              <!-- <slot :planName="'user'"></slot>
              <slot :planName="'dsgds'"></slot> -->

              <p class="my-3 mx-auto max-w-4xl text-lg leading-7 text-gray-500 dark:text-white sm:text-center"
                >Choose the best plan for your establishment based on your typical number of check-ins per month. All subscriptions are set
                to automatically renew each month unless you cancel before the next renewal date. During peak periods, you can top up your
                check-in credits without having to change your subscription.
              </p>
              <!-- <div class="my-5 mx-auto p-6 w-1/2 shadow overflow-hidden sm:rounded bg-red-50 border border-red-300">
                <p class="text-lg leading-7 text-gray-500 dark:text-white sm:text-center"
                  ><span class="font-semibold text-xl text-gray-900">Busier than usual?</span><br />
                  No worries! Purchase extra check-ins by topping up your credits at any time.</p
                >
              </div> -->
            </div>
            <div v-if="isMarketing" class="my-8 justify-center">
              <div class="m-auto flex justify-center">
                <p
                  class="
                    text-3xl
                    font-extrabold
                    tracking-tight
                    text-gray-900
                    sm:text-3xl
                    dark:text-white dark:bg-gray-700
                    text-grey-500
                    sm:text-center
                    text-center
                  "
                  >Trial our software with <span class="text-red-600"> 50 free </span> credits when you sign up!</p
                >
              </div>
              <div class="mt-8 flex justify-center">
                <div class="rounded-md shadow"><button class="text-white red-button-lg" @click="signIn">Sign Up</button></div>
              </div>
            </div>
            <slot name="admin"></slot>
            <div
              class="
                container
                mt-10
                max-w-7xl max-w-screen-xl
                mx-auto
                bg-white
                dark:bg-gray-700 dark:text-white
                py-16
                sm:py-24 sm:px-6
                lg:px-8
              "
            >
              <div class="max-w-2xl mx-auto lg:hidden">
                <div class="px-4">
                  <h2 class="text-lg leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-900">{{ small.name }}</h2>
                  <p class="mt-4">
                    <span class="text-4xl leading-10 font-extrabold dark:text-white dark:bg-gray-700 text-gray-900"
                      >R{{ small.price }}</span
                    >
                    <span class="text-base leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-500">/pm</span>
                  </p>
                  <p class="mt-4 text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-500">{{ small.description }}<br /> </p>

                  <slot name="planName" :planName="'small'"></slot>
                </div>
                <table class="mt-8 w-full">
                  <caption
                    class="
                      bg-gray-50
                      border-t border-gray-200
                      py-3
                      px-4
                      text-sm
                      leading-5
                      font-medium
                      dark:text-white dark:bg-gray-700
                      text-gray-900 text-left
                    "
                  >
                    Features</caption
                  >
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Check-in credits
                      </th>
                      <td class="py-5 pr-4">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-right"
                          >Up to {{ small.checkInCredits }}</span
                        >
                        <span class="sr-only">{{ small.checkInCredits }}</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Users</th
                      >
                      <td class="py-5 pr-4">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-right">{{
                          small.users
                        }}</span>
                        <span class="sr-only">{{ small.users }}</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Dashboard view
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500 text-right"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Credit top-up
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500 text-right"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Digital indemnity signatures</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Health screening
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Upload additional documents</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Branded email invites
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >SMS guest notifications
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Automatic guest check-in reminders
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Scannable QR Code</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Email support
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 dark:text-white dark:bg-gray-700 text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg
                        ><span class="sr-only">No</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="
                          py-5
                          px-4
                          text-sm
                          leading-5
                          font-normal
                          dark:text-white dark:bg-gray-700
                          text-gray-500
                          dark:text-white dark:bg-gray-700
                          text-gray-500 text-left
                        "
                        scope="row"
                        >Priority support
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 dark:text-white dark:bg-gray-700 text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg
                        ><span class="sr-only">No</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="border-t border-gray-200 px-4 pt-5">
                  <SignUpButton v-if="isMarketing" />
                  <SubscribeButton v-else @update="pop()" />
                </div>
                <div class="px-4 mt-16">
                  <h2 class="text-lg leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-900">{{ medium.name }}</h2>
                  <p class="mt-4">
                    <span class="text-4xl leading-10 font-extrabold dark:text-white dark:bg-gray-700 text-gray-900"
                      >R{{ medium.price }}</span
                    >
                    <span class="text-base leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-500">/pm</span>
                  </p>
                  <p class="mt-4 text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-500">{{ medium.description }}.</p>
                  <slot name="planName" :planName="'Medium'"></slot>
                </div>
                <table class="mt-8 w-full">
                  <caption
                    class="
                      bg-gray-50
                      border-t border-gray-200
                      py-3
                      px-4
                      text-sm
                      leading-5
                      font-medium
                      dark:text-white dark:bg-gray-700
                      text-gray-900 text-left
                    "
                  >
                    Features</caption
                  >
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Check-in credits
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-right"
                          >Up to {{ medium.checkInCredits }}</span
                        >
                        <span class="sr-only">{{ medium.checkInCredits }}</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Users</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-right">4</span>
                        <span class="sr-only">{{ medium.users }}</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Dashboard view
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Credit top-up
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Digital indemnity signatures</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Health screening
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Upload additional documents</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Branded email invites
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >SMS guest notifications
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Automatic guest check-in reminders
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Scannable QR Code</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Email support
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Priority support
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 dark:text-white dark:bg-gray-700 text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg
                        ><span class="sr-only">No</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="border-t border-gray-200 px-4 pt-5">
                  <SignUpButton v-if="isMarketing" />
                  <SubscribeButton v-else @update="pop()" />
                </div>
                <div class="px-4 mt-16">
                  <h2 class="text-lg leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-900">{{ large.name }}</h2>
                  <p class="mt-4">
                    <span class="text-4xl leading-10 font-extrabold dark:text-white dark:bg-gray-700 text-gray-900"
                      >R{{ large.price }}</span
                    >
                    <span class="text-base leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-500">/pm</span>
                  </p>
                  <p class="mt-4 text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-500">{{ large.description }}</p>
                  <slot name="planName" :planName="'Large'"></slot>
                </div>
                <table class="mt-8 w-full">
                  <caption
                    class="
                      bg-gray-50
                      border-t border-gray-200
                      py-3
                      px-4
                      text-sm
                      leading-5
                      font-medium
                      dark:text-white dark:bg-gray-700
                      text-gray-900 text-left
                    "
                  >
                    Features</caption
                  >
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Check-in credits
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-right"
                          >Up to {{ large.checkInCredits }}</span
                        >
                        <span class="sr-only">{{ large.checkInCredits }}</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Users</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-right">{{
                          large.users
                        }}</span>
                        <span class="sr-only">{{ large.users }}</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Dashboard view
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Credit top-up
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Digital indemnity signatures</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Health screening
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Upload additional documents</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="sr-only" scope="col">Feature</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Branded email invites
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >SMS guest notifications
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Automatic guest check-in reminders
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Scannable QR Code</th
                      >
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Email support
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="ml-auto h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Priority support
                      </th>
                      <td class="py-5 pr-4 text-right">
                        <svg
                          class="h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Yes</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="border-t border-gray-200 px-4 pt-5">
                  <slot name="planName" :planName="'Large'"></slot>
                </div>
              </div>
              <div class="hidden lg:block">
                <table class="w-full h-px table-fixed">
                  <caption class="sr-only">Pricing plan comparison</caption>
                  <thead>
                    <tr>
                      <th
                        class="pb-4 px-6 text-sm leading-5 font-medium dark:text-white dark:bg-gray-700 text-gray-900 text-left"
                        scope="col"
                      >
                        <span class="sr-only">Feature by</span>
                        <span>Plans</span>
                      </th>
                      <th
                        class="w-1/4 pb-4 px-6 text-lg leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-900 text-left"
                        scope="col"
                        >{{ small.name }}</th
                      >
                      <th
                        class="w-1/4 pb-4 px-6 text-lg leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-900 text-left"
                        scope="col"
                        >{{ medium.name }}
                      </th>
                      <th
                        class="w-1/4 pb-4 px-6 text-lg leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-900 text-left"
                        scope="col"
                        >{{ large.name }}</th
                      >
                    </tr>
                  </thead>
                  <tbody class="border-t border-gray-200 divide-y divide-gray-200">
                    <tr>
                      <th
                        class="py-8 px-6 text-sm leading-5 font-medium dark:text-white dark:bg-gray-700 text-gray-900 text-left align-top"
                        scope="row"
                      >
                        Pricing</th
                      >
                      <td class="h-full py-8 px-6 align-top">
                        <div class="relative h-full table">
                          <p>
                            <span class="text-4xl leading-10 font-extrabold dark:text-white dark:bg-gray-700 text-gray-900"
                              >R{{ small.price }}</span
                            >
                            <span class="text-base leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-500">/pm</span>
                          </p>
                          <p class="mt-6 mb-20 text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-500"
                            >{{ small.description }} <br />
                          </p>
                          <slot name="planName" :planName="'Small'"></slot>
                        </div>
                      </td>
                      <td class="h-full py-8 px-6 align-top">
                        <div class="relative h-full table">
                          <p>
                            <span class="text-4xl leading-10 font-extrabold dark:text-white dark:bg-gray-700 text-gray-900"
                              >R{{ medium.price }}</span
                            >
                            <span class="text-base leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-500">/pm</span>
                          </p>
                          <p class="mt-4 mb-16 text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-500">{{
                            medium.description
                          }}</p>
                          <slot name="planName" :planName="'Medium'"></slot>
                        </div>
                      </td>
                      <td class="h-full py-8 px-6 align-top">
                        <div class="relative h-full table">
                          <p>
                            <span class="text-4xl leading-10 font-extrabold dark:text-white dark:bg-gray-700 text-gray-900"
                              >R{{ large.price }}</span
                            >
                            <span class="text-base leading-6 font-medium dark:text-white dark:bg-gray-700 text-gray-500">/pm</span>
                          </p>
                          <p class="mt-4 mb-16 text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-500">{{ large.description }}</p>

                          <slot name="planName" :planName="'Large'"></slot>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="bg-gray-50 py-3 pl-6 text-sm leading-5 font-medium dark:text-white dark:bg-gray-700 text-gray-900 text-left"
                        colspan="4"
                        scope="colgroup"
                        >Features</th
                      >
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Check-in credits
                      </th>
                      <td class="py-5 px-6">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-center"
                          >Up to {{ small.checkInCredits }} check-ins</span
                        >
                      </td>
                      <td class="py-5 px-6">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-center"
                          >Up to {{ medium.checkInCredits }} check-ins</span
                        >
                      </td>
                      <td class="py-5 px-6">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-center"
                          >Up to {{ large.checkInCredits }} check-ins</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Users</th
                      >
                      <td class="py-5 px-6">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-center"
                          >{{ small.users }} User</span
                        >
                      </td>
                      <td class="py-5 px-6">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-center"
                          >{{ medium.users }} Users</span
                        >
                      </td>
                      <td class="py-5 px-6">
                        <span class="block text-sm leading-5 dark:text-white dark:bg-gray-700 text-gray-700 text-center"
                          >{{ large.users }} Users</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Dashboard view
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Credit top-up
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Digital indemnity signatures</th
                      >
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Health screening
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Upload additional documents</th
                      >
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Branded email invites
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >SMS guest notifications
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Automatic guest check-in reminders
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Scannable QR Code</th
                      >
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Email support
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                    <tr class="">
                      <th
                        class="py-5 px-6 text-sm leading-5 font-normal dark:text-white dark:bg-gray-700 text-gray-500 text-left"
                        scope="row"
                        >Priority support
                      </th>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 dark:text-white dark:bg-gray-700 text-gray-400 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg
                        ><span class="sr-only">Not included in Small</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 dark:text-white dark:bg-gray-700 text-gray-400 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg
                        ><span class="sr-only">Not Included in Medium</span>
                      </td>
                      <td class="py-5 px-6 text-center">
                        <svg
                          class="h-5 w-5 text-green-500 m-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path></svg
                        ><span class="sr-only">Included in Large</span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="border-t border-gray-200">
                      <th class="sr-only" scope="row">Choose your plan</th>
                      <td class="pt-5 px-6">
                        <slot name="planName" :planName="'Small'"></slot>
                      </td>
                      <td class="pt-5 px-6">
                        <slot name="planName" :planName="'Medium'"></slot>
                      </td>
                      <td class="pt-5 px-6">
                        <slot name="planName" :planName="'Large'"></slot>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.vs-dialog__content {
  background-color: #f9fafb !important;
  background-color: rgba(249, 250, 251, var(--bg-opacity)) !important;
}
.pricing-table-1 .fusion-panel,
.pricing-table-1 .standout .fusion-panel,
.pricing-table-1 .panel-wrapper:last-child .fusion-panel,
.pricing-table-1 .panel-heading,
.pricing-table-1 .panel-body,
.pricing-table-1 .panel-footer {
  border-color: #e2e2e2;
}
.pricing-table-2 .fusion-panel,
.pricing-table-2 .standout .fusion-panel,
.pricing-table-2 .panel-wrapper:last-child .fusion-panel,
.pricing-table-2 .panel-heading,
.pricing-table-2 .panel-body,
.pricing-table-2 .panel-footer {
  border-color: #e2e2e2;
}
.pricing-table-2.full-boxed-pricing .panel-heading {
  background-color: rgba(255, 255, 255, 0);
}
@media only screen and (max-width: 800px) {
  .fusion-title.fusion-title-6 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.sub-panel {
  &:hover {
    border-color: #66999b;
    .DetailItem {
      .DetailTitle {
        color: #ea5455 !important;
        font-weight: 900;
      }
    }
  }
}
.sep-boxed-pricing .panel-heading {
  padding: 15px;
  text-align: center;
  background-color: #66999b;
  background-clip: padding-box;
  border-color: #66999b;
  border-bottom: 1px solid #e5e4e3;
  border-radius: 0;
}
.panel-container.active {
  border: #e95a5d;
  border-style: solid;
  border-width: 2px;
  .panel-heading {
    background: #e95a5d;
    .h3 {
      color: white;
    }
  }
}
.fusion-pricing-table .panel-body .price .integer-part {
  display: inline;
  color: #66999b;
  font-weight: 700;
  line-height: normal;
}
.sep-boxed-pricing .panel-body .price .integer-part {
  font-size: 45px;
}
.pricing-table-1.fusion-pricing-table .panel-body .price .integer-part {
  color: #e95a5d;
}
.fusion-pricing-table .panel-body {
  padding: 15px;
  text-align: center;
}
.panel-wrapper {
  flex: 1 0 21%;
  margin: 5px;
}
.sub-button {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 10px;
}
.fusion-pricing-table .list-group-item {
  position: relative;
  display: block;
  margin: 0;
  padding: 15px;
  text-align: center;
  background: 0 0;
  border-top: 1px solid #e5e4e3;
  border-radius: 0;
}
.panel-container {
  transition: box-shadow 0.3s;
}
.panel-container:hover {
  box-shadow: 20px 10px 40px 0 rgba(0, 0, 0, 0.5);
}
</style>

<style type="text/css">
.pricing-table-1.full-boxed-pricing .panel-heading {
  background-color: rgba(255, 255, 255, 0);
}
.pricing-table-1.full-boxed-pricing .panel-wrapper:hover .panel-heading,
.pricing-table-1 .panel-wrapper:hover .list-group-item {
  background-color: #f2f3f5;
}
.sub-panel {
  width: 25% !important;
}
.pricing-table-1 .panel-body,
.pricing-table-1 .panel-footer {
  background-color: #f2f3f5;
}
.pricing-table-1.sep-boxed-pricing .panel-heading h3 {
  color: #fff;
}
.pricing-table-1.full-boxed-pricing.fusion-pricing-table .panel-heading h3 {
  color: #333;
}
.pricing-table-1.fusion-pricing-table .panel-body .price .decimal-part {
  color: #e95a5d;
}
.pricing-table-1.fusion-pricing-table .panel-body .price .integer-part {
  color: #e95a5d;
}
.pricing-table-1 ul.list-group li {
  color: #333;
}
</style>

<style type="text/css">
.pricing-table-2 .panel-container,
.pricing-table-2 .standout .panel-container,
.pricing-table-2.full-boxed-pricing {
  background-color: #f2f3f5;
}
.pricing-table-2 .list-group .list-group-item,
.pricing-table-2 .list-group .list-group-item:last-child {
  background-color: rgba(255, 255, 255, 0);
  border-color: #e2e2e2;
}
.pricing-table-2.full-boxed-pricing .panel-wrapper:hover .panel-heading,
.pricing-table-2 .panel-wrapper:hover .list-group-item {
  background-color: #f2f3f5;
}
.pricing-table-2 .panel-body,
.pricing-table-2 .panel-footer {
  background-color: #f2f3f5;
}
.pricing-table-2.sep-boxed-pricing .panel-heading h3 {
  color: #fff;
}
.pricing-table-2.full-boxed-pricing.fusion-pricing-table .panel-heading h3 {
  color: #333;
}
.pricing-table-2.fusion-pricing-table .panel-body .price .decimal-part {
  color: #66999b;
}
.pricing-table-2.fusion-pricing-table .panel-body .price .integer-part {
  color: #66999b;
}
.pricing-table-2 ul.list-group li {
  color: #333;
}
.fusion-button.button-1 {
  border-radius: 10px;
}
</style>
